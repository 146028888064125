import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/Home';
import NewMedReq from './pages/NewMedReq';
import Approvals from './pages/Approval';
import Settings from './pages/Settings';
import SignIn from './pages/LoginBox';
import SubclaimsApproval from './pages/SubclaimsApproval';
import Subclaims from './pages/Subclaims'
import CsvExports from './pages/CsvExports'
import ApprClaims from './pages/ApprClaims';
import Claims from './pages/Claims';
import { ThemeProvider } from '@mui/material/styles';
import Theme from './Theme';
import PrivateRoutes from './PrivateRoutes';
import ForgotPassword from './pages/ForgotPassword';





export default function App() {



  return (


    <><ThemeProvider theme={Theme}>
      <Router>
        
        <Routes>
          
          <Route element={<PrivateRoutes />}>

           <Route path='/home' exact element={<Home />}  />
           <Route path='/newreq' exact element={<NewMedReq />}> </Route>
          <Route path='/approvals' exact element={<Approvals />}> </Route>
          <Route path='/settings' exact element={<Settings />}> </Route>
          <Route path='/subclaims_approval' exact element={<SubclaimsApproval />}> </Route>
          <Route path='/subclaims' exact element={<Subclaims />}> </Route>
          <Route path='/claimsdata' exact element={<Claims />}> </Route>
          <Route path='/csv_exports' exact element={<CsvExports />}> </Route>
          <Route path='/appr_claims' exact element={<ApprClaims />}> </Route>


          </Route>

          <Route path='/' exact element={<SignIn />} />
          <Route path='/forgot_password' exact element={<ForgotPassword />} />
          
        </Routes>
      </Router>
    </ThemeProvider>
    </>
  )
}






