import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Sidenav from '../components/Sidenav';
import Box from '@mui/material/Box';
import Navbar from '../components/Navbar';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import DataTable, { Media } from 'react-data-table-component';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useLocation } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import ArrowDownward from '@mui/icons-material/KeyboardArrowDownSharp';
import { ScaleLoader } from 'react-spinners';
import Typography from '@mui/material/Typography';



const Home = () => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const location = useLocation();
  const [claimsData, setClaimsData] = useState([]);
  const [filteredClaims, setfilteredClaims] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState('');
  const [attachmentIndex, setAttachmentIndex] = useState(0);
  const { claimId, staff_no } = location.state || {};
  const [approvedAmounts, setApprovedAmounts] = useState({});
  const [approvedComments, setApprovedComments] = useState({});
  const [employeeDetails, setEmployeeDetails] = useState({
    claimApprovedAmount: '',
    entitlements_plan: '',
    entitlements_limit: '',
    claimAmountPending: '',
    remBalance: '',
    emp_name: '',
    ini_staff_no: '',
    username: '',
    claimAmount: ''
  });

  const navigate = useNavigate();


  useEffect(() => {
    const fetchClaimData = async () => {
      try {
        const retrievedToken = retrieveToken();
        if (!retrievedToken) {
          return;
        }
        setLoading(true);
        const userData = retrieveUserData();
        const response = await axios.get(`https://pkclaims.vergemobile.com/api/subclaims_approval`, {
          headers: {
            Authorization: `Bearer ${retrievedToken}`,
          },
          params: {
            claimId: claimId,
            staff_no: staff_no,

          },
        });
        const empObj = response.data.empData;
        const claimApprovedAmount = empObj.claimApprovedAmount;
        const entitlements_plan = empObj.entitlements_plan;
        const entitlements_limit = empObj.entitlements_limit;
        const claimAmountPending = empObj.claimAmountPending;
        const remBalance = entitlements_limit - claimApprovedAmount; 
        const emp_name = empObj.employee;
        const ini_staff_no = empObj.staff_no;
        const username = empObj.username;
        const claimAmount = empObj.claimAmount;

        setEmployeeDetails({
          claimApprovedAmount,
          entitlements_plan,
          entitlements_limit,
          claimAmountPending,
          remBalance,
          emp_name,
          ini_staff_no,
          username,
          claimAmount

        });
        setLoading(false);
        setClaimsData(response.data.formattedClaims);
        setfilteredClaims(response.data.formattedClaims);

      } catch (error) {
        console.error(error.response.data.message, error);
        if (error.response.data.error === 'Invalid token.') {
          Swal.fire({
            title: 'Session Expired',
            text: 'Your session has expired. Please log in again.',
            width: 400,
          }).then(() => {
            localStorage.removeItem('token_key');
            localStorage.removeItem('token_expiration');
            navigate('/');
          });
        }
        if (error.response.data.error !== 'Invalid token.') {
          Swal.fire({
            title: 'Error',
            text: 'An error occurred while fetching the claims.',
            width: 400,
          });

        }
        setLoading(false);
      }
    };

    fetchClaimData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const result = claimsData.filter(claimdata => {

      return claimdata.patients_name.toLowerCase().match(search.toLowerCase());
    })
    setfilteredClaims(result);
  }, [search]);

  const columns = [
    {
      name: 'Relation',
      selector: row => row.relation,
      sortable: true,
      width: '120px',
      hide: Media.LG
    },
    {
      name: 'Name',
      selector: row => row.patients_name,
      sortable: true,
      width: '155px',
      hide: Media.LG
    },
    {
      name: 'Date',
      selector: row => row.date,
      sortable: true,
      width: '110px',
      hide: Media.MD
    },

    {
      name: 'Attachment',
      width: '120px',
      selector: (row) => <Button
        variant="contained"
        size='small'
        color='primary'
        onClick={() => handleOpenDialog(row.attachments)}

      >
        Image
      </Button>

    },
    {
      name: 'Amount',
      selector: row => row.amount,
      sortable: true,
      width: '120px'
    },
    {
      name: 'Appr. Amount',
      cell: (row) => (
        <input
          type='number'
          style={{ width: '100%', padding: '8px', boxSizing: 'border-box', border: '1px solid #ccc', borderRadius: '4px' }}
          min='0'
          max='6'
          value={approvedAmounts[row.subclaim_id] || ''}
          onChange={(e) => {
            const approvedAmount = parseFloat(e.target.value) || 0;
            if (approvedAmount >= 0 && approvedAmount <= row.amount) {
              setApprovedAmounts((prevAmounts) => ({
                ...prevAmounts,
                [row.subclaim_id]: e.target.value,
              }));
            }
          }}
          placeholder='Approved Amount'
        />
      ),
      sortable: true,
      width: '150px'
    },
    {
      name: 'Approved Comments',
      cell: (row) => (
        <textarea
          variant='outlined'
          style={{ margin: 'dense', width: '100%', padding: '8px', height: '35px', margin: '2px 0px', resize: 'vertical', border: '1px solid #ccc', boxSizing: 'border-box' }}
          rows={4}
          maxLength={600}
          value={approvedComments[row.subclaim_id] || ''}
          onChange={(e) => {
            setApprovedComments((prevComments) => ({
              ...prevComments,
              [row.subclaim_id]: e.target.value,
            }));
          }}
          placeholder='Approved Comments'
        />
      ),
      width: '250px',
      wrap: true,
      hide: Media.MD,
    }

  ];

  const retrieveToken = () => {
    return localStorage.getItem('token_key');
  };


  const handleApproveClick = async (approvalValue) => {
    let confirmationMessage = '';
    let successMessage = '';
    let approval_string = '';

    if (approvalValue === 1) {
      confirmationMessage = 'You are about to approve the claim. Are you sure?';
      successMessage = 'The claim has been approved.';
      approval_string = 'approved';
    } else if (approvalValue === 2) {
      confirmationMessage = 'You are about to reject the claim. Are you sure?';
      successMessage = 'The claim has been rejected.';
      approval_string = 'rejected';
    }

    const result = await Swal.fire({
      title: `Confirm ${approval_string.charAt(0).toUpperCase() + approval_string.slice(1)} Claim`,
      text: confirmationMessage,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `Yes, ${approval_string.charAt(0).toUpperCase() + approval_string.slice(1)} it!`,
      width: 400,
    });

    if (result.isConfirmed) {
      setLoading(true);
      try {
        const retrievedToken = localStorage.getItem('token_key');
        if (!retrievedToken) {
          navigate('/');
          return;
        }

        const userData = retrieveUserData();
        const approver_email = userData.username;



        const subClaimsData = claimsData.map((subClaim) => ({
          subclaim_id: subClaim.subclaim_id,
          amount: subClaim.amount,
          approved_amount: approvedAmounts[subClaim.subclaim_id],
          approved_comments: approvedComments[subClaim.subclaim_id]
        }));

        await axios.post(
          `https://pkclaims.vergemobile.com/api/claim_approval`,
          {
            claim_details: {
              claimId: claimId,
              approvalValue: approvalValue,
              subClaimsData: subClaimsData,
              approver_email: approver_email,
            },
            emp_details: {
              staff_no: employeeDetails.ini_staff_no,
              emp_name: employeeDetails.emp_name,
              username: employeeDetails.username,
              claimAmount: employeeDetails.claimAmount,
            },

          },
          {
            headers: {
              Authorization: `Bearer ${retrievedToken}`,
            },
          }
        );
        Swal.fire({
          title: 'Success',
          text: `Claim ${approval_string}`,
          showConfirmButton: true,
          width: 400,
        }).then(() => {
          navigate('/approvals');
        });
        setLoading(false);
      } catch (error) {
        console.error(error.response.data.message, error);
        if (error.response.data.error === 'Invalid token.') {
          Swal.fire({
            title: 'Session Expired',
            text: 'Your session has expired. Please log in again.',
            width: 400,
          }).then(() => {
            localStorage.removeItem('token_key');
            localStorage.removeItem('token_expiration');
            navigate('/');
          });
        }
        if (error.response.data.error !== 'Invalid token.') {
          Swal.fire({
            title: 'Error',
            text: 'An error occurred while approving the claims.',
            width: 400,
          });

        }
        setLoading(false);
      }
    }
  };


  const handleOpenDialog = (attachment) => {
    setSelectedAttachment(attachment);
    setOpenDialog(true);
  };

  const handleNextAttachment = () => {
    const nextIndex = (attachmentIndex + 1) % filteredClaims.length;
    setSelectedAttachment(filteredClaims[nextIndex].attachments);
    setAttachmentIndex(nextIndex);
  };

  const handleCloseDialog = () => {
    setSelectedAttachment('');
    setOpenDialog(false);
  };

  const retrieveUserData = () => {
    const retrievedToken = localStorage.getItem('token_key');
    const decodedToken = jwtDecode(retrievedToken);
    const user = decodedToken.user;
    const staff_no = decodedToken.user.staff_no
    const emp_name = decodedToken.user.emp_name
    const username = decodedToken.user.username
    return {
      staff_no: staff_no,
      emp_name: emp_name,
      username: username,
      user: user
    };
  };
  const customStyles = {

    headRow: {
      style: {
        background: 'linear-gradient(90deg, #cc2b5e, #753a88)',
        color: 'white',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '10px 0px'

        // paddingBottom: '10px'
      }
    },
    pagination: {
      style: {
        color: 'white',
        // backgroundColor: 'linear-gradient(90deg, #cc2b5e, #753a88)',
        background: 'linear-gradient(90deg, #cc2b5e, #753a88)',
        // height: '40px'
      },
    }, rows: {
      style: {
        padding: '3px 0px',
        color: 'black',
        // fontWeight: '550',
        fontSize: '0.9rem',
        border: '1px solid  #e91e63', // Border color for all rows
      },
    },
    subHeader: {
      style: {
        justifyContent: 'space-evenly',
        padding: '0px',
        background: 'linear-gradient(90deg, #cc2b5e, #753a88)',
        borderBottom: '2px solid #eef2f6'
      }
    }
  };
  const sortIcon = <ArrowDownward />;
  const customPaginationOptions = {
    rowsPerPageText: 'Rows per page:',
    rangeSeparatorText: 'of',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'All',
  };

  return (
    <>

      {loading ? (
        <>
          <div>
            <div className="bgcolor" style={{
              paddingBottom: '130px',
              paddingTop: '40px',
              height: '100vh'
            }}>
              <Navbar />
              <Box height={50} />
              <Box sx={{ display: 'flex' }}>
                <Sidenav />
                <Box component="main" className="bgcolor" sx={{ flexGrow: 1, p: 3 }}>

                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px', marginTop: '-50px' }}>
                    <TextField
                      id="Search"
                      label="Name"
                      variant="standard"
                      size="small"
                      type='text'
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      sx={{ marginLeft: 'auto' }}
                    />
                  </div>



                  <div style={{ display: 'flex', marginBottom: '3px', background: 'linear-gradient(90deg, #cc2b5e, #753a88)' }}>

                    <div style={{ width: '100%', marginLeft: '1px' }}>
                    
                      <Typography variant="body1" style={{ color: 'white' }}>#</Typography>
                      
                      <Typography variant="body1" style={{ color: 'white' }}>{`CMS-${claimId.toString().padStart(5, '0')}`}</Typography>
                     
                    </div>

                    <div style={{ width: '100%' }}>
                      <Typography variant="body1" style={{ color: 'white' }}>Name:</Typography>
                      <Typography variant="body1" style={{ color: 'white' }}>{employeeDetails.emp_name}</Typography>
                    </div>

                    <div style={{ width: '100%' }}>
                      <Typography variant="body1" style={{ color: 'white' }}>Limit:</Typography>
                      <Typography variant="body1" style={{ color: 'white' }}>{employeeDetails.entitlements_limit}</Typography>
                    </div>

                    <div style={{ width: '100%' }}>
                      <Typography variant="body1" style={{ color: 'white' }}>Tot.Pend.Amnt:</Typography>
                      <Typography variant="body1" style={{ color: 'white' }}>{employeeDetails.claimAmountPending}</Typography>
                    </div>

                    <div style={{ width: '100%' }}>
                      <Typography variant="body1" style={{ color: 'white' }}> Tot.Apr.Amnt:</Typography>
                      <Typography variant="body1" style={{ color: 'white' }}>{employeeDetails.claimApprovedAmount}</Typography>
                    </div>
                    <div style={{ width: '100%' }}>
                      <Typography variant="body1" style={{ color: 'white' }}>Rem.Bal:</Typography>
                      <Typography variant="body1" style={{ color: 'white' }}>{employeeDetails.remBalance}</Typography>
                    </div>
                  </div>
                  <DataTable
                    columns={columns}
                    data={filteredClaims}
                    pagination
                    paginationComponentOptions={customPaginationOptions}
                    paginationPerPage={20}
                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                    fixedHeader
                    fixedHeaderScrollHeight='350px'
                    selectableRows
                    selectableRowsHighlight
                    highlightOnHover
                    customStyles={customStyles}
                    responsive
                    striped
                    dense
                    sortIcon={sortIcon}
               
                  />

                  <Box mt={1}>
                    <Button variant='contained' style={{ backgroundColor: '#3085d6', color: 'white', marginRight: '10px' }} onClick={() => handleApproveClick(1)}>
                      Approve Claim
                    </Button>
                    <Button variant='contained' style={{ backgroundColor: '#d33', color: 'white' }} onClick={() => handleApproveClick(2)}>
                      Reject Claim
                    </Button>
                  </Box>
                </Box>
              </Box>
            </div>
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              maxWidth='md'
              fullWidth={true}
              PaperProps={{
                style: {
                  height: '90vh',
                  width: '100vh',
                },
              }}
            >
              <DialogTitle>Attachment</DialogTitle>
              <DialogContent>
                {selectedAttachment && (
                  <>
                    {selectedAttachment.endsWith('.pdf') ? (
                      <object
                        data={`https://pkclaims.vergemobile.com/images/${selectedAttachment}`}
                        type='application/pdf'
                        width='100%'
                        height='100%'
                        style={{ border: 'none' }}
                      >
                        <p>PDF cannot be displayed. Please download the file to view it.</p>
                      </object>
                    ) : (
                      <img
                        src={`https://pkclaims.vergemobile.com/images/${selectedAttachment}`}
                        alt='Attachment'
                        style={{ maxWidth: '100%' }}
                      />
                    )}
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color='primary'>
                  Close
                </Button>
                {filteredClaims.length > 1 && (
                  <Button onClick={handleNextAttachment} color='primary'>
                    Next
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          </div>
        </>

      ) : (
        <>
          <div>
            <div className="bgcolor" style={{
              paddingBottom: '130px',
              paddingTop: '40px',
              height: '100vh'
            }}>
              <Navbar />
              <Box height={50} />
              <Box sx={{ display: 'flex' }}>
                <Sidenav />
                <Box component="main" className="bgcolor" sx={{ flexGrow: 1, p: 3, width: '80vw'  }}>

                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px', marginTop: '-30px' }}>
                    <TextField
                      id="Search"
                      label="Name"
                      variant="standard"
                      size="small"
                      type='text'
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      sx={{ marginLeft: 'auto' }}
                    />
                  </div>



                  <div style={{ display: 'flex', marginBottom: '3px', background: 'linear-gradient(90deg, #cc2b5e, #753a88)' }}>

                    <div style={{ width: '100%', marginLeft: '1px', textAlign:'center' }}>
                     
                      <Typography variant="body1" style={{ color: 'white' }}>#</Typography>
                    
                      <Typography variant="body1" style={{ color: 'white' }}>{`CMS-${claimId.toString().padStart(5, '0')}`}</Typography>
                    
                    </div>

                    <div style={{ width: '100%', textAlign:'center' }}>
                      <Typography variant="body1" style={{ color: 'white' }}>Name:</Typography>
                      <Typography variant="body1" style={{ color: 'white' }}>{employeeDetails.emp_name}</Typography>
                    </div>

                    <div style={{ width: '100%', textAlign:'center' }}>
                      <Typography variant="body1" style={{ color: 'white' }}>Limit:</Typography>
                      <Typography variant="body1" style={{ color: 'white' }}>{employeeDetails.entitlements_limit}</Typography>
                    </div>

                    <div style={{ width: '100%', textAlign:'center' }}>
                      <Typography variant="body1" style={{ color: 'white' }}>Tot.Pend.Amnt:</Typography>
                      <Typography variant="body1" style={{ color: 'white' }}>{employeeDetails.claimAmountPending}</Typography>
                    </div>

                    <div style={{ width: '100%', textAlign:'center' }}>
                      <Typography variant="body1" style={{ color: 'white' }}> Tot.Apr.Amnt:</Typography>
                      <Typography variant="body1" style={{ color: 'white' }}>{employeeDetails.claimApprovedAmount}</Typography>
                    </div>
                    <div style={{ width: '100%' }}>
                      <Typography variant="body1" style={{ color: 'white' }}>Rem.Bal:</Typography>
                      <Typography variant="body1" style={{ color: 'white' }}>{employeeDetails.remBalance}</Typography>
                    </div>
                  </div>
                  <DataTable
                    columns={columns}
                    data={filteredClaims}
                    pagination
                    paginationComponentOptions={customPaginationOptions}
                    paginationPerPage={20}
                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                    fixedHeader
                    fixedHeaderScrollHeight='350px'
                    selectableRows
                    selectableRowsHighlight
                    highlightOnHover
                    customStyles={customStyles}
                    responsive
                    striped
                    dense
                    sortIcon={sortIcon}
             
                  />

                  <Box mt={1}>
                    <Button variant='contained' style={{ backgroundColor: '#3085d6', color: 'white', marginRight: '10px' }} onClick={() => handleApproveClick(1)}>
                      Approve Claim
                    </Button>
                    <Button variant='contained' style={{ backgroundColor: '#d33', color: 'white' }} onClick={() => handleApproveClick(2)}>
                      Reject Claim
                    </Button>
                  </Box>
                </Box>
              </Box>
            </div>
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              maxWidth='md'
              fullWidth={true}
              PaperProps={{
                style: {
                  height: '90vh',
                  width: '100vh',
                },
              }}
            >
              <DialogTitle>Attachment</DialogTitle>
              <DialogContent>
                {selectedAttachment && (
                  <>
                    {selectedAttachment.endsWith('.pdf') ? (
                      <object
                        data={`https://pkclaims.vergemobile.com/images/${selectedAttachment}`}
                        type='application/pdf'
                        width='100%'
                        height='100%'
                        style={{ border: 'none' }}
                      >
                        <p>PDF cannot be displayed. Please download the file to view it.</p>
                      </object>
                    ) : (
                      <img
                        src={`https://pkclaims.vergemobile.com/images/${selectedAttachment}`}
                        alt='Attachment'
                        style={{ maxWidth: '100%' }}
                      />
                    )}
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color='primary'>
                  Close
                </Button>
                {filteredClaims.length > 1 && (
                  <Button onClick={handleNextAttachment} color='primary'>
                    Next
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          </div>
        </>
      )}

    </>
  );
}
export default Home;