import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Sidenav from '../components/Sidenav';
import Navbar from '../components/Navbar';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import '../Home.css';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import img from './bg/VergeMobile_png_1.png'
import { ScaleLoader } from 'react-spinners'

const CsvExports = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [csvData, setCsvData] = useState([]);
  const retrieveToken = () => {
    return localStorage.getItem('token_key');
  };

  const headers = [
    { label: 'Claim #', key: 'claim_id' },
    { label: 'Staff No.', key: 'staff_no' },
    { label: 'Employee', key: 'emp_name' },
    { label: 'Relation', key: 'relation' },
    { label: 'Name', key: 'patients_name' },
    { label: 'Subclaim Date', key: 'subclaim_date' },
    { label: 'Subclaim Amount', key: 'subclaim_amount' },
    { label: 'Subclaim Approved Amount', key: 'subclaim_approved_amount' },
    { label: 'Subclaim Approved Comments', key: 'approved_comments' },
    { label: 'Claim Date', key: 'claim_date' },
    { label: 'Approved Date', key: 'approved_date' },
    { label: 'Total Claim Amount', key: 'total_amount' },
    { label: 'Total Approved Amount', key: 'total_approved_amount' },
    { label: 'Approved By', key: 'approved_by' },
    { label: 'Status', key: 'status_label' },
  ];

  const handleExport = async () => {
    try {
      const retrievedToken = retrieveToken();
      if (!retrievedToken) {
        navigate('/');
        return;
      }
      setLoading(true);
      const res = await axios.get(`https://pkclaims.vergemobile.com/api/csv_exports`, {
        headers: {
          Authorization: `Bearer ${retrievedToken}`,
        },
      });

      const lines = res.data.split('\n');

      const data = lines.map(line => {
        const [claim_id, staff_no, emp_name,relation,patients_name,subclaim_date, subclaim_amount,  subclaim_approved_amount,subclaim_approved_comments,claim_date,approved_date,total_amount,total_approved_amount,approved_by,status_label] = line.split(',');
        return {
          claim_id,
          staff_no,
          emp_name,
          relation,
          patients_name,
          subclaim_date:new Date(subclaim_date).toLocaleDateString(),
          subclaim_amount,
          subclaim_approved_amount,
          subclaim_approved_comments,
          claim_date: new Date(claim_date).toLocaleString(),
          approved_date: approved_date ? new Date(approved_date).toLocaleString() : '',
          total_amount,
          total_approved_amount,
          approved_by,
          status_label
        };
      });

      setCsvData(data);
      setLoading(false);
    } catch (error) {
      console.error(error.response.data.message, error);
      if (error.response.data.error === 'Invalid token.') {
        Swal.fire({
          title: 'Session Expired',
          text: 'Your session has expired. Please log in again.',
          width: 400,
        }).then(() => {
          localStorage.removeItem('token_key');
          localStorage.removeItem('token_expiration');
          navigate('/');
        });
      }
      if (error.response.data.error !== 'Invalid token.') {
        Swal.fire({
          title: 'Error',
          text: 'An error occurred while fetching the claims.',
          width: 400,
        });
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    handleExport(); // Call handleExport when the component mounts
  }, []); // Empty dependency array ensures this effect runs only once, similar to componentDidMount

  return (
    <>
      {loading ? (
        <div style={{ backgroundColor: 'black', width: '100vw', height: '100vh', position: 'absolute', zIndex: '5', opacity: '0.7' }}>{/*Top Div*/}
          <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}>
            <img src={img} alt='verge_logo' style={{ height: "90px", marginBottom: "15px" }} />
            <ScaleLoader
              color="#e91e63"
              height={60}
              margin={4}
              radius={2}
              width={6}
            />
          </div>
          <div>
            <div className='bgcolor' style={{
              height: '100vh'
            }}>
              <Navbar />
              <Box height={70} />
              <Box sx={{ display: "flex" }}>
                <Sidenav />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                  <h1>Csv Exports</h1>
                  {/* Button removed, directly rendering CSVLink */}
                  <button
                    style={{
                      backgroundColor: 'rgb(233, 30, 99)',
                      borderRadius: '20px',
                      border: '2px solid black',
                      padding: '7px 0px'
                    }}>
                    <CSVLink
                      data={csvData}
                      headers={headers}
                      filename="Claims.csv"
                      className="hidden"
                      style={{
                        textDecoration: 'none',
                        color: 'white',
                        padding: '10px 15px'
                      }}
                    >
                      Export to CSV
                    </CSVLink>
                  </button>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      ) : (
        <div className='bgcolor' style={{
          height: '100vh'
        }}>
          <Navbar />
          <Box height={70} />
          <Box sx={{ display: "flex" }}>
            <Sidenav />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <h1>Csv Exports</h1>
              {/* Button removed, directly rendering CSVLink */}
              <button
                style={{
                  backgroundColor: 'rgb(233, 30, 99)',
                  borderRadius: '20px',
                  border: '2px solid black',
                  padding: '7px 0px'
                }}>
                <CSVLink
                  data={csvData}
                  headers={headers}
                  filename="Claims.csv"
                  className="hidden"
                  style={{
                    textDecoration: 'none',
                    color: 'white',
                    padding: '10px 15px'
                  }}
                >
                  Export to CSV
                </CSVLink>
              </button>
            </Box>
          </Box>
        </div>
      )}
    </>
  );
}
 export default CsvExports;