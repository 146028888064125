import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#e91e63',
    },
  },
  // typography:{
  //   fontFamily:[
  //   'Playfair Display',
  //   'serif'
  // ].join(',')
  // },
});


export default theme;
