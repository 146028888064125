import React, { useEffect, useState } from 'react';
import axios, { HttpStatusCode } from 'axios';
import Sidenav from '../components/Sidenav';
import Box from '@mui/material/Box';
import Navbar from '../components/Navbar';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import DataTable, { Media } from 'react-data-table-component';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useLocation } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import ArrowDownward from '@mui/icons-material/KeyboardArrowDownSharp';
import { ScaleLoader } from 'react-spinners'
import img from './bg/VergeMobile_png_1.png'
import Typography from '@mui/material/Typography';



const Home = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [claimsData, setClaimsData] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredClaims, setfilteredClaims] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState('');
  const [attachmentIndex, setAttachmentIndex] = useState(0);
  const { claimId, status, claim_date, amount, appr_amount, appr_date } = location.state || {};

  const navigate = useNavigate();

  useEffect(() => {
    const fetchClaimData = async () => {
      try {
        const retrievedToken = localStorage.getItem('token_key');
        if (!retrievedToken) {
          navigate('/');
          return;
        }
        setLoading(true);
        const userData = retrieveUserData();
        const retrievedStaff_no = userData.staff_no;

        const response = await axios.get(`https://pkclaims.vergemobile.com/api/subclaims`, {
          headers: {
            Authorization: `Bearer ${retrievedToken}`,
          },
          params: {
            claimId: claimId,
            staff_no: retrievedStaff_no,
          },
        });
        setLoading(false);
        setClaimsData(response.data);
        setfilteredClaims(response.data);

      } catch (error) {
        console.error(error.response.data.message, error);
        if (error.response.data.error === 'Invalid token.') {
          Swal.fire({
            title: 'Session Expired',
            text: 'Your session has expired. Please log in again.',
            width: 400,
          }).then(() => {
            localStorage.removeItem('token_key');
            localStorage.removeItem('token_expiration');
            navigate('/');
          });
        }
        if (error.response.data.error !== 'Invalid token.') {
          Swal.fire({
            title: 'Error',
            text: 'An error occurred while fetching the claims.',
            width: 400,
          });
        }
        setLoading(false);
      }
    };

    fetchClaimData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const result = claimsData.filter(claimdata => {

      return claimdata.patients_name.toLowerCase().match(search.toLowerCase());
    })
    setfilteredClaims(result);
  }, [search]);

  const columns = [
    {
      name: 'Relation',
      selector: row => row.relation,
      sortable: true,
      width: '120px',
      hide: Media.MD
    },
    {
      name: 'Name',
      selector: row => row.patients_name,
      sortable: true,
      width: '155px'
    },
    {
      name: 'Claim Date',

      selector: row => row.date,
      sortable: true,
      width: '130px',
      hide: Media.MD

    },

    {
      name: 'Attachment',
      width: '120px',
      selector: (row) => <Button
        variant="contained"
        size='small'
        color='primary'
        onClick={() => handleOpenDialog(row.attachments)}
      >
        Image
      </Button>
    },
    {
      name: 'Amount',
      selector: row => row.amount,
      sortable: true,
      width: '120px'
    },
    {
      name: 'Approved Amount',
      selector: row => row.approved_amount,
      sortable: true,
    },
    {
      name: 'Approved Comments',
      selector: row => row.approved_comments,
      sortable: true,
      wrap: true,
      width: '250px',
      hide: Media.MD
    },

  ];

  const retrieveToken = () => {
    return localStorage.getItem('token_key');
  };

  const retrieveUserData = () => {
    const retrievedToken = localStorage.getItem('token_key');
    const decodedToken = jwtDecode(retrievedToken);
    const user = decodedToken.user;
    const staff_no = decodedToken.user.staff_no
    const emp_name = decodedToken.user.emp_name
    const username = decodedToken.user.username
    return {
      staff_no: staff_no,
      emp_name: emp_name,
      username: username,
      user: user
    };
  };

  const customStyles = {

    headRow: {
      style: {
        background: 'linear-gradient(45deg, #7b4397, #9e5158de)',
        color: 'white',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '10px 0px',
        // paddingBottom: '10px'
      }
    },
    pagination: {
      style: {
        color: 'white',
        // backgroundColor: 'linear-gradient(45deg, #7b4397, #9e5158de)',
        background: 'linear-gradient(45deg, #7b4397, #9e5158de)',
        // height: '40px'
      },
    }, rows: {
      style: {
        padding: '3px 0px',
        color: 'black',
        // fontWeight: '550',
        fontSize: '0.9rem',
        border: '1px solid  #e91e63', // Border color for all rows
      },
    },
    subHeader: {
      style: {
        justifyContent: 'space-evenly',
        padding: '0px',
        paddingLeft: '100px',
        background: 'linear-gradient(45deg, #7b4397, #9e5158de)',
        borderBottom: '2px solid #eef2f6'
      }
    }
  };
  const handleOpenDialog = (attachment) => {
    setSelectedAttachment(attachment);
    setOpenDialog(true);
  };

  const handleNextAttachment = () => {
    const nextIndex = (attachmentIndex + 1) % filteredClaims.length;
    setSelectedAttachment(filteredClaims[nextIndex].attachments);
    setAttachmentIndex(nextIndex);
  };

  const handleCloseDialog = () => {
    setSelectedAttachment('');
    setOpenDialog(false);
  };
  const sortIcon = <ArrowDownward />;
  const customPaginationOptions = {
    rowsPerPageText: 'Rows per page:',
    rangeSeparatorText: 'of',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'All',
  };
  return (
    <>
      {loading ? (
        <>
          <div style={{ backgroundColor: 'black', width: '100vw', height: '100vh', position: 'absolute', zIndex: '5', opacity: '0.7' }}>{/*Top Div*/}
            <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}>
              <img src={img} alt='verge_logo' style={{ height: "90px", marginBottom: "15px" }} />
              <ScaleLoader
                color="#e91e63"
                height={60}
                margin={4}
                radius={2}
                width={6}
              />
            </div>
            <div className="bgcolor" style={{
              paddingBottom: '130px',
              paddingTop: '40px',
              height: '100vh'
            }}>
              <Navbar />
              <Box height={70} />
              <Box sx={{ display: 'flex' }}>
                <Sidenav />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px', marginTop: '-50px' }}>
                    <TextField
                      id="Search"
                      label="Name"
                      variant="standard"
                      size="small"
                      type='text'
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      sx={{ marginLeft: 'auto' }}
                    />
                  </div>
                  <div style={{ display: 'flex', marginBottom: '3px', background: 'linear-gradient(45deg, #7b4397, #9e5158de)', padding: '0px 10px' }}>

                    <div style={{ width: '100%', marginLeft: '1px', textAlign: 'center' }}>
                      {/* <div style={{ width: '100%' }}> */}
                      <Typography variant="body1" style={{ color: 'white' }}>#</Typography>
                      {/* </div> */}
                      {/* <div> */}
                      <Typography variant="body1" style={{ color: 'white' }}>{`CMS-${claimId.toString().padStart(5, '0')}`}</Typography>
                      {/* </div> */}
                    </div>

                    <div style={{ width: '100%', textAlign: 'center' }}>
                      <Typography variant="body1" style={{ color: 'white' }}>Status</Typography>
                      <Typography variant="body1" style={{ color: 'white' }}>{status}</Typography>
                    </div>

                    <div style={{ width: '100%', textAlign: 'center' }}>
                      <Typography variant="body1" style={{ color: 'white' }}>Date:</Typography>
                      <Typography variant="body1" style={{ color: 'white' }}>{claim_date}</Typography>
                    </div>

                    <div style={{ width: '100%', textAlign: 'center' }}>
                      <Typography variant="body1" style={{ color: 'white' }}>Appr.Date:</Typography>
                      <Typography variant="body1" style={{ color: 'white' }}>{appr_date}</Typography>
                    </div>

 

                    <div style={{ width: '100%', textAlign: 'center' }}>
                      <Typography variant="body1" style={{ color: 'white' }}> Appr.Amount:</Typography>
                      <Typography variant="body1" style={{ color: 'white' }}>{appr_amount}</Typography>
                    </div>
                  </div>
                  <DataTable
                    columns={columns}
                    data={filteredClaims}
                    pagination
                    paginationComponentOptions={customPaginationOptions}
                    paginationPerPage={20}
                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                    fixedHeader
                    fixedHeaderScrollHeight='350px'
                    selectableRows
                    selectableRowsHighlight
                    highlightOnHover
                    customStyles={customStyles}
                    responsive
                    striped
                    sortIcon={sortIcon}
                    dense
                  />
                </Box>
              </Box>
            </div>
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              maxWidth='md'
              fullWidth={true}
              PaperProps={{
                style: {
                  height: '90vh',
                  width: '100vh',
                },
              }}
            >
              <DialogTitle>Attachment</DialogTitle>
              <DialogContent>
                {selectedAttachment && (
                  <>
                    {selectedAttachment.endsWith('.pdf') ? (
                      <object
                        data={`https://pkclaims.vergemobile.com/images/${selectedAttachment}`}
                        type='application/pdf'
                        width='100%'
                        height='100%'
                        style={{ border: 'none' }}
                      >
                        <p>PDF cannot be displayed. Please download the file to view it.</p>
                      </object>
                    ) : (
                      <img
                        src={`https://pkclaims.vergemobile.com/images/${selectedAttachment}`}
                        alt='Attachment'
                        style={{ maxWidth: '100%' }}
                      />
                    )}
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color='primary'>
                  Close
                </Button>
                {filteredClaims.length > 1 && (
                  <Button onClick={handleNextAttachment} color='primary'>
                    Next
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          </div>
        </>
      ) : (
        <>
          <div>


            <div className="bgcolor" style={{
              paddingBottom: '130px',
              paddingTop: '40px',
              height: '100vh'
            }}>
              <Navbar />
              <Box height={70} />
              <Box sx={{ display: 'flex' }}>
                <Sidenav />
                <Box component="main" className="bgcolor" sx={{ flexGrow: 1, p: 3, width: '80vw' }}>

                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px', marginTop: '-50px' }}>
                    <TextField
                      id="Search"
                      label="Name"
                      variant="standard"
                      size="small"
                      type='text'
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      sx={{ marginLeft: 'auto' }}
                    />
                  </div>
                  <div style={{ display: 'flex', marginBottom: '3px', background: 'linear-gradient(45deg, #7b4397, #9e5158de)', padding: '0px 10px' }}>

                    <div style={{ width: '100%', marginLeft: '1px', textAlign: 'center' }}>
                     
                      <Typography variant="body1" style={{ color: 'white' }}>#</Typography>
                     
                      <Typography variant="body1" style={{ color: 'white' }}>{`CMS-${claimId.toString().padStart(5, '0')}`}</Typography>
                     
                    </div>

                    <div style={{ width: '100%', textAlign: 'center' }}>
                      <Typography variant="body1" style={{ color: 'white' }}>Status</Typography>
                      <Typography variant="body1" style={{ color: 'white' }}>{status}</Typography>
                    </div>

                    <div style={{ width: '100%', textAlign: 'center' }}>
                      <Typography variant="body1" style={{ color: 'white' }}>Date:</Typography>
                      <Typography variant="body1" style={{ color: 'white' }}>{claim_date}</Typography>
                    </div>

                    <div style={{ width: '100%', textAlign: 'center' }}>
                      <Typography variant="body1" style={{ color: 'white' }}>Appr.Date:</Typography>
                      <Typography variant="body1" style={{ color: 'white' }}>{appr_date}</Typography>
                    </div>

                    <div style={{ width: '100%', textAlign: 'center' }}>
                      <Typography variant="body1" style={{ color: 'white' }}> Appr.Amount:</Typography>
                      <Typography variant="body1" style={{ color: 'white' }}>{appr_amount}</Typography>
                    </div>
                  </div>
                  <DataTable
                    columns={columns}
                    data={filteredClaims}
                    fixedHeader
                    pagination
                    paginationComponentOptions={customPaginationOptions}
                    paginationPerPage={20}
                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                    fixedHeaderScrollHeight='350px'
                    selectableRows
                    selectableRowsHighlight
                    highlightOnHover
                    customStyles={customStyles}
                    responsive
                    striped
                    sortIcon={sortIcon}
                    dense
                  />
                </Box>
              </Box>
            </div>
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              maxWidth='md'
              fullWidth={true}
              PaperProps={{
                style: {
                  height: '90vh',
                  width: '100vh',
                },
              }}
            >
              <DialogTitle>Attachment</DialogTitle>
              <DialogContent>
                {selectedAttachment && (
                  <>
                    {selectedAttachment.endsWith('.pdf') ? (
                      <object
                        data={`https://pkclaims.vergemobile.com/images/${selectedAttachment}`}
                        type='application/pdf'
                        width='100%'
                        height='100%'
                        style={{ border: 'none' }}
                      >
                        <p>PDF cannot be displayed. Please download the file to view it.</p>
                      </object>
                    ) : (
                      <img
                        src={`https://pkclaims.vergemobile.com/images/${selectedAttachment}`}
                        alt='Attachment'
                        style={{ maxWidth: '100%' }}
                      />
                    )}
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color='primary'>
                  Close
                </Button>
                {filteredClaims.length > 1 && (
                  <Button onClick={handleNextAttachment} color='primary'>
                    Next
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          </div>
        </>
      )}
    </>
  );



}
export default Home;