import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Sidenav from '../components/Sidenav';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Navbar from '../components/Navbar';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import { CardActionArea } from '@mui/material';
import '../Home.css';
import PendingActionsSharpIcon from '@mui/icons-material/PendingActionsSharp';
import LocalAtmSharpIcon from '@mui/icons-material/LocalAtmSharp';
import AccountBalanceSharpIcon from '@mui/icons-material/AccountBalanceSharp';
import RemoveDoneSharpIcon from '@mui/icons-material/RemoveDoneSharp';
import Swal from 'sweetalert2';
import { jwtDecode } from "jwt-decode";
import { ScaleLoader } from 'react-spinners'
import img from './bg/VergeMobile_png_1.png'


const Home = () => {
  const [loading, setLoading] = useState(false);
  const [claimsPending, setClaimsPending] = useState();
  const [claimAmountPending, setClaimAmountPending] = useState();
  const [claimApprovedAmount, setClaimApprovedAmount] = useState();
  const [claimsRejected, setClaimsRejected] = useState();


  const navigate = useNavigate();



  useEffect(() => {
    const fetchClaimData = async () => {
      try {
        const retrievedToken = localStorage.getItem('token_key');
        if (!retrievedToken) {
          return;
        }
        setLoading(true);
        const decodedToken = jwtDecode(retrievedToken);
        const staff_no = decodedToken.user.staff_no



        const response = await axios.get(`https://pkclaims.vergemobile.com/api/homedata`, {
          headers: {
            Authorization: `Bearer ${retrievedToken}`,
          },
          params: {
            staff_no: staff_no
          },
        });
        const data = response.data.dashboard_data;
        setClaimsPending(`${data.claimsPending}`);
        setClaimAmountPending(`${data.claimAmountPending}`);
        setClaimApprovedAmount(`${data.claimApprovedAmount}`);
        setClaimsRejected(`${data.claimsRejected - data.claimApprovedAmount}`);

        setLoading(false);
      } catch (error) {
        console.error(error.response.data.message, error);
        if (error.response.data.error === 'Invalid token.') {
          Swal.fire({
            title: 'Session Expired',
            text: 'Your session has expired. Please log in again.',
            width: 400,
          }).then(() => {
            localStorage.removeItem('token_key');
            localStorage.removeItem('token_expiration');
            navigate('/');
          });

        }
        setLoading(false);
      }
    };

    fetchClaimData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
         <>

      {loading ? (
          <>
          <div style={{ backgroundColor: 'black', width: '100vw', height: '100vh', position: 'absolute', zIndex: '5', opacity: '0.7' }}>{/*Top Div*/}

            <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', display: 'flex',flexDirection: 'column' ,justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}>
            <img src={img} alt='verge_logo' style={{height: "90px", marginBottom: "15px" }}/>
              <ScaleLoader color="#e91e63" height={60} margin={4} radius={2} width={6} />
            </div>
            <div>
            <div className='bgcolor'>
              <Navbar />
              <Box height={70} />

              <Box sx={{ display: "flex" }} >
                <Sidenav />
                {loading && (
                  <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', display: 'flex',flexDirection: 'column' ,justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}>
                  <img src={img} alt='verge_logo' style={{height: "90px", marginBottom: "15px" }}/>
                    <ScaleLoader color="#e91e63" height={60} margin={4} radius={2} width={6} />
                  </div>
                )}
                <Box component='main' sx={{ flexGrow: 1, }}>

                  <Grid container spacing={4} pl={2} pr={5} pt={1}  >

                    <Grid item xs={12} sm={11} md={4} lg={3}>

                      <Card sx={{ background: 'linear-gradient(90deg, #cc2b5e, #753a88)', height: 130 }}>

                          <CardContent >

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div>
                                <Typography style={{ color: '#fff' }} >
                                  Pending Claims
                                </Typography>
                              </div>
                              <div>
                                <PendingActionsSharpIcon />
                              </div>
                            </div>



                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px' }}>
                              <Typography variant="h5" sx={{ color: '#fff', fontSize: '45px', fontWeight: 'bold' }}>
                                {claimsPending}
                              </Typography>
                            </div>



                          </CardContent>
                      </Card>
                    </Grid>



                    <Grid item xs={12} sm={11} md={4} lg={3} >
                      <Card sx={{ background: 'linear-gradient(90deg, #614385, #516395)', height: 130 }}>
                            <CardContent >
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div>
                                <Typography style={{ color: '#fff' }} >
                                  Pending Amount
                                </Typography>

                              </div>
                              <div>
                                <LocalAtmSharpIcon />
                              </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px' }}>
                              <Typography variant="h5" sx={{ color: '#fff', fontSize: '45px', fontWeight: 'bold' }}>
                                {claimAmountPending}
                              </Typography>
                            </div>
                            </CardContent>
                      </Card>
                    </Grid>


                    <Grid item xs={12} sm={11} md={4} lg={3} >
                      <Card sx={{ background: 'linear-gradient(90deg, #3a1c71, #d76d77)', height: 130 }}>


                        
                          <CardContent>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div>
                                <Typography style={{ color: '#fff' }} >
                                  Amount Approved
                                </Typography>

                              </div>
                              <div>
                                <AccountBalanceSharpIcon />
                              </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px' }}>
                              <Typography variant="h5" sx={{ color: '#fff', fontSize: '45px', fontWeight: 'bold' }}>
                                {claimApprovedAmount}
                              </Typography>
                            </div>
                          </CardContent>
                        
                      </Card>
                    </Grid>

                    <Grid item xs={12} sm={11} md={4} lg={3}>
                      <Card sx={{ background: 'linear-gradient(90deg, #c33764, #1d2671)', height: 130 }}>

                        
                          <CardContent>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div>
                                <Typography style={{ color: '#fff' }} >
                                  OPD Balance
                                </Typography>

                              </div>
                              <div>
                                <RemoveDoneSharpIcon />
                              </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px' }}>
                              <Typography variant="h5" sx={{ color: '#fff', fontSize: '45px', fontWeight: 'bold' }}>
                                {claimsRejected}
                              </Typography>
                            </div>
                          </CardContent>
                        
                      </Card>


                    </Grid>

                  </Grid>

                  <Box height={20} sx={{ backgroundcolor: 'gradient' }} />
                  <Grid container spacing={2} pl={2} pr={5} pt={1}  >
                    <Stack direction="row" spacing={2}>

                      <Grid item l={6} >

                      </Grid>




                    </Stack>

                  </Grid>


                  <Box height={10} />

                </Box>
              </Box>


            </div>
            </div>
            </div>
          </>
          
          ) : (
            <div>
            <div className='bgcolor'>
              <Navbar />
              <Box height={70} />

              <Box sx={{ display: "flex" }} >
                <Sidenav />
                {loading && (
                  <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 5 }}>
                    <ScaleLoader color="#e91e63" height={60} margin={4} radius={2} width={6} />
                  </div>
                )}
                <Box component='main' sx={{ flexGrow: 1, }}>

                  <Grid container spacing={4} pl={2} pr={5} pt={1}  >

                    <Grid item xs={12} sm={11} md={4} lg={3}>

                      <Card sx={{ background: 'linear-gradient(90deg, #cc2b5e, #753a88)', height: 130 }}>

                          <CardContent >

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div>
                                <Typography style={{ color: '#fff' }} >
                                  Pending Claims
                                </Typography>
                              </div>
                              <div>
                                <PendingActionsSharpIcon />
                              </div>
                            </div>



                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px' }}>
                              <Typography variant="h5" sx={{ color: '#fff', fontSize: '45px', fontWeight: 'bold' }}>
                                {claimsPending}
                              </Typography>
                            </div>



                          </CardContent>
                      </Card>
                    </Grid>



                    <Grid item xs={12} sm={11} md={4} lg={3} >
                      <Card sx={{ background: 'linear-gradient(90deg, #614385, #516395)', height: 130 }}>

                          <CardContent>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div>
                                <Typography style={{ color: '#fff' }} >
                                  Pending Amount
                                </Typography>

                              </div>
                              <div>
                                <LocalAtmSharpIcon />
                              </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px' }}>
                              <Typography variant="h5" sx={{ color: '#fff', fontSize: '45px', fontWeight: 'bold' }}>
                                {claimAmountPending}
                              </Typography>
                            </div>
                          </CardContent>
                      </Card>
                    </Grid>


                    <Grid item xs={12} sm={11} md={4} lg={3} >
                      <Card sx={{ background: 'linear-gradient(90deg, #3a1c71, #d76d77)', height: 130 }}>


                          <CardContent>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div>
                                <Typography style={{ color: '#fff' }} >
                                  Amount Approved
                                </Typography>

                              </div>
                              <div>
                                <AccountBalanceSharpIcon />
                              </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px' }}>
                              <Typography variant="h5" sx={{ color: '#fff', fontSize: '45px', fontWeight: 'bold' }}>
                                {claimApprovedAmount}
                              </Typography>
                            </div>
                          </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12} sm={11} md={4} lg={3}>
                      <Card sx={{ background: 'linear-gradient(90deg, #c33764, #1d2671)', height: 130 }}>

                          <CardContent>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div>
                                <Typography style={{ color: '#fff' }} >
                                  OPD Balance
                                </Typography>

                              </div>
                              <div>
                                <RemoveDoneSharpIcon  />
                              </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px' }}>
                              <Typography variant="h5" sx={{ color: '#fff', fontSize: '45px', fontWeight: 'bold' }}>
                                {claimsRejected}
                              </Typography>
                            </div>
                          </CardContent>
                      </Card>


                    </Grid>

                  </Grid>

                  <Box height={20} sx={{ backgroundcolor: 'gradient' }} />
                  <Grid container spacing={2} pl={2} pr={5} pt={1}  >
                    <Stack direction="row" spacing={2}>

                      <Grid item l={6} >

                      </Grid>




                    </Stack>

                  </Grid>


                  <Box height={10} />

                </Box>
              </Box>


            </div>
            </div>

             )}  
        </>
      )
}

      export default Home;