import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';


const PrivateRoutes = () =>{
 const token =   localStorage.getItem('token_key');
 return (
    token ? <Outlet/> : <Navigate to={'/'}/>
 )
};

export default PrivateRoutes;
