
import React, { useState } from 'react';
import Sidenav from '../components/Sidenav';
import Navbar from '../components/Navbar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import AlertTitle from '@mui/material/AlertTitle';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import '../Home.css';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from "yup";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import { jwtDecode } from "jwt-decode";
import parse from "date-fns/parse";
import { ScaleLoader } from 'react-spinners'
import img from './bg/VergeMobile_png_1.png'




const validationSchema = yup.object().shape({
  users: yup.array().of(
    yup.object().shape({
      relation: yup.string().required('relation is required'),
      name: yup.string().required('name is required'),
      date: yup
        .date()
        .transform(function (value, originalValue) {
          if (this.isType(value)) {
            return value;
          }
          const result = parse(originalValue, "dd/MM/yyyy", new Date());
          return result;
        })
        .typeError("please enter a valid date")
        .required()
        .min("2024-01-01", "The date must be later than 2023."),

      amount: yup
        .number()
        .integer('please enter a whole number')
        .positive("must be a positive number")
        .lessThan(100000, 'please enter a value less than 100,000.')
        .required('amount is required'),
      file: yup
        .mixed()
        .test('fileFormat', 'Only JPG, PNG, or PDF files are allowed', (value) => {
          if (!value) return true;

          const allowedExtensions = ['.jpg', '.jpeg', '.png', '.pdf'];
          const extension = value.name.split('.').pop().toLowerCase();

          return allowedExtensions.includes(`.${extension}`);
        })
        .test('fileSize', 'File size must be less than 10MB', (value) => {
          if (!value) return true;
          return value.size <= 1024 * 1024 * 10;
        })
        .required('File is required'),
    })
  ),
});




const NewMedReq = () => {

  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarError, setSnackbarError] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      users: [{ relation: '', name: '', file: '', date: null, amount: '' }],
    },
    validationSchema: validationSchema,
    onSubmit: async (values, e) => {

      const confirmed = await Swal.fire({
        title: 'Claim Submission',
        text: 'Are you sure you want to proceed?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, submit it!',
        width: 400,
      });

      if (confirmed.isConfirmed) {
        const retrievedToken = localStorage.getItem('token_key');
        if (!retrievedToken) {
          return;
        }
        setLoading(true);

        const userData = retrieveUserData();
        const staff_no = userData.staff_no;
        const emp_name = userData.emp_name;
        const username = userData.username;

        if (values.users.length < 1) {
          setSnackbarOpen(true);
          setSnackbarError(true);
          setSnackbarMessage('minimum one line required to begin the transaction.');
          setLoading(false);
          return;
        }

        const fileNames = values.users.map(user => user.file ? user.file.name : null);
        const duplicateFileName = fileNames.find((name, index) => fileNames.indexOf(name) !== index);



        if (duplicateFileName) {
          setSnackbarOpen(true);
          setSnackbarError(true);
          setSnackbarMessage(`Duplicate file name: ${duplicateFileName}`);
          setLoading(false);
          return;
        } else {
          try {
            const response = await axios.post('https://pkclaims.vergemobile.com/api/newreq', values, {
              headers: {
                Authorization: `Bearer ${retrievedToken}`,
                'Content-Type': 'multipart/form-data',
              },
              params: {
                staff_no: staff_no,
                emp_name: emp_name,
                username: username
              },
            });

            Swal.fire({
              title: 'Success',
              text: `Claim submitted successfully! Claim ID: ${response.data.claimId}`,
              showConfirmButton: true,
              width: 400,
            }).then(() => {
              navigate('/home');
            });

            setLoading(false);
          } catch (error) {
            console.error(error.response.data.message, error);
            if (error.response.data.error === 'Invalid token.') {
              Swal.fire({
                title: 'Session Expired',
                text: 'Your session has expired. Please log in again.',
                width: 400,
              }).then(() => {
                localStorage.removeItem('token_key');
                localStorage.removeItem('token_expiration');
                navigate('/');
              });
            }
            if (error.response.data.error !== 'Invalid token.') {
              Swal.fire({
                title: 'Error',
                text: 'An error occurred while submitting the claim.',
                width: 400,
              });

            }
            setLoading(false);
          }
        }

      }

    },
  });




  const retrieveUserData = () => {
    const retrievedToken = localStorage.getItem('token_key');
    const decodedToken = jwtDecode(retrievedToken);
    const user = decodedToken.user;
    const staff_no = decodedToken.user.staff_no
    const emp_name = decodedToken.user.emp_name
    const username = decodedToken.user.username
    return {
      staff_no: staff_no,
      emp_name: emp_name,
      username: username,
      user: user
    };
  };


  const userData = retrieveUserData();
  const userObj = userData.user;


  const addUser = () => {
    const currentLineCount = formik.values.users.length;
    if (currentLineCount < 20) {
      formik.setFieldValue('users', [
        ...formik.values.users,
        { relation: '', name: '', date: null, amount: '', file: '' },
      ]);
    } else {
      setSnackbarMessage(`You have reached the maximum limit of 20 lines per claim. `);
      setSnackbarOpen(true);
      setSnackbarError(true);
    }
  };
  const removeUser = (index) => {
    const updatedUsers = formik.values.users.filter((user, i) => i !== index);
    formik.setFieldValue('users', updatedUsers);
  };


  const generateDropdownOptions = () => {
    const options = Object.entries(userRel).map(([label, value]) => ({
      label,
      value,
    }));

    return options;
  };
  const userRel = {};
  const propertiesToCheck = ['emp_name', 'spouse_name', 'father_name', 'mother_name', 'first_child', 'second_child', 'third_child', 'fourth_child', 'fifth_child', 'sixth_child', 'seventh_child', 'eight_child'];
  for (const property of propertiesToCheck) {
    if (userObj.hasOwnProperty(property) && userObj[property] !== null && userObj[property] !== undefined && userObj[property] !== '') {
      if (property === ('emp_name')) {
        userRel['Self'] = userObj[property]
      }
      else if (property === ('spouse_name')) {
        userRel['Spouse'] = userObj[property]
      }
      else if (property === ('father_name')) {
        userRel['Father'] = userObj[property]
      }
      else if (property === ('mother_name')) {
        userRel['Mother'] = userObj[property]
      }
      else if (property === ('first_child')) {
        userRel['First Child'] = userObj[property]
      }
      else if (property === ('second_child')) {
        userRel['Second Child'] = userObj[property]
      }
      else if (property === ('third_child')) {
        userRel['Third Child'] = userObj[property]
      }
      else if (property === ('fourth_child')) {
        userRel['Fourth Child'] = userObj[property]
      }
      else if (property === ('fifth_child')) {
        userRel['Fifth Child'] = userObj[property]
      }
      else if (property === ('sixth_child')) {
        userRel['Sixth Child'] = userObj[property]
      }
      else if (property === ('seventh_child')) {
        userRel['Seventh Child'] = userObj[property]
      }
      else if (property === ('seventh_child')) {
        userRel['Seventh Child'] = userObj[property]
      }
      else if (property === ('eight_child')) {
        userRel['Eigth Child'] = userObj[property]
      }
      else {
        userRel[property] = userObj[property];
      }
    }
  }

  const options = generateDropdownOptions();









  return (
    <>
      {/* <BeatLoader loading={true} color='black'/> */}
      {loading ? (
        <>
          <div style={{ backgroundColor: 'black', width: '100vw', height: '100vh', position: 'absolute', zIndex: '5', opacity: '0.7' }}>{/*Top Div*/}

            <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', display: 'flex',flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}>
            <img src={img} alt='verge_logo' style={{height: "90px", marginBottom: "15px" }}/>
            <ScaleLoader
  color="#e91e63"
  height={60}
  margin={4}
  radius={2}
  width={6}
/>
            </div>
            <div>

              <body className="bgcolor">
                <Navbar />
                <div style={{ height: '50px' }} />s
                <div style={{ display: 'flex' }}>
                  <Sidenav />



                  <div component="main" style={{ flexGrow: 1, padding: '1rem', backgroundColor: '#eef2f6' }}>
                    <div style={{ marginBottom: '1rem' }}>
                      <div style={{ paddingBottom: '10px', textAlign: 'left' }}>
                        <div style={{ backgroundColor: '#ffebee', padding: '16px', justifyContent: 'center', alignItems: 'center', display: 'flex', border: '1px solid #e91e63' }}>
                          <Typography variant="h4" color="primary">
                            Employee Details
                          </Typography>
                        </div>
                        <div style={{ height: '60px', backgroundColor: 'white', marginTop: '0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxShadow: '0 3px 3px -2px rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.12)' }}>
                          <TextField
                            disabled
                            id="standard-disabled"
                            label="Name"
                            variant="standard"
                            value={userObj.emp_name}
                            size='small'
                            sx={{ marginRight: '10px', marginLeft: '10px' }}
                          />
                          <TextField
                            disabled
                            id="standard-disabled"
                            label="Department"
                            variant="standard"
                            value={userObj.department}
                            size='small'
                            sx={{ marginRight: '10px' }}
                          />
                          <TextField
                            disabled
                            id="standard-disabled"
                            label="OPD Limit"
                            variant="standard"
                            value={userObj.opd_limit}
                            size='small'
                            sx={{ marginRight: '10px' }}
                          />

                        </div>

                      </div>
                    </div>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <form onSubmit={formik.handleSubmit}
                          enableReinitialize={true}>
                          <div component={Box} style={{ boxShadow: '0 3px 3px -2px rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.12)', backgroundColor: 'white' }}>
                            <div style={{ backgroundColor: '#ffebee', padding: '16px', justifyContent: 'center', alignItems: 'center', display: 'flex', border: '1px solid #e91e63' }}>
                              <Typography variant="h4" color="primary">
                                Initiate Claim Request
                              </Typography>
                            </div>
                            <div
                              style={{
                                marginTop: '5px',
                                borderRadius: '8px',
                                marginBottom: '16px',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >

                            </div>
                            {formik.values.users.map((user, index) => (
                              <Grid container spacing={0.5} justifyContent="center" key={index}>

                                <Grid item md={2} p={1}   >
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Relation</InputLabel>
                                    <Select
                                      name={`users[${index}].relation`}
                                      value={formik.values.users[index].relation}
                                      label="Relation"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}

                                      error={formik.touched.users && formik.touched.users[index]?.relation && Boolean(formik.errors?.users?.[index]?.relation)}
                                      helperText={formik.touched.users && formik.touched.users[index]?.relation && formik.errors?.users?.[index]?.relation}
                                      style={{
                                        minWidth: '60px'
                                      }}
                                    >
                                      {options.map((option) => (
                                        <MenuItem key={option.label} value={option.label}>
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    <p style={{ color: '#d32f2f', fontSize: '12px' }}>
                                      {formik.touched.users &&
                                        formik.touched.users[index]?.relation &&
                                        formik.errors?.users?.[index]?.relation && (
                                          <span style={{ display: 'block' }}>
                                            {formik.errors?.users?.[index]?.relation}
                                          </span>
                                        )}
                                    </p>
                                  </FormControl>
                                </Grid>

                                <Grid item md={2.5} p={1} elevation={3}>
                                  <FormControl fullWidth>
                                    <InputLabel >Name</InputLabel>
                                    <Select
                                      label="Name"
                                      name={`users[${index}].name`}
                                      fullWidth
                                      value={formik.values.users[index].name}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      error={formik.touched.users && formik.touched.users[index]?.name && Boolean(formik.errors?.users?.[index]?.name)}
                                      helperText={formik.touched.users && formik.touched.users[index]?.name && formik.errors?.users?.[index]?.name}
                                    >
                                      {options.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                          {option.value}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    <p style={{ color: '#d32f2f', fontSize: '12px' }}>
                                      {formik.touched.users &&
                                        formik.touched.users[index]?.name &&
                                        formik.errors?.users?.[index]?.name && (
                                          <span style={{ display: 'block' }}>
                                            {formik.errors?.users?.[index]?.name}
                                          </span>
                                        )}
                                    </p>
                                  </FormControl>
                                </Grid>

                                <Grid item md={2} p={1}>
                                  <TextField
                                    label="Amount"
                                    type="number"
                                    name={`users[${index}].amount`}
                                    placeholder="Reimbursement Amount"
                                    fullWidth
                                    value={formik.values.users[index].amount}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.users && formik.touched.users[index]?.amount && Boolean(formik.errors?.users?.[index]?.amount)}
                                    helperText={formik.touched.users && formik.touched.users[index]?.amount && formik.errors?.users?.[index]?.amount}
                                  />

                                </Grid>
                                <Grid item md={2} p={1}>
                                  <FormControl fullWidth>

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                      <DatePicker
                                        name={`users[${index}].date`}
                                        label='Date'
                                        value={formik.values.users[index].date ? formik.values.users[index].date : null}
                                        variant="outlined"
                                        onChange={(date) => formik.setFieldValue(`users[${index}].date`, date)}
                                        onBlur={formik.handleBlur}
                                      />
                                    </LocalizationProvider>
                                    {formik.touched.users && formik.touched.users[index]?.date && Boolean(formik.errors?.users?.[index]?.date) && (
                                      <p style={{ color: '#d32f2f', fontSize: '12px' }}>
                                        {formik.errors?.users?.[index]?.date}
                                      </p>
                                    )}
                                  </FormControl>
                                </Grid>

                                <Grid item md={2} p={1}>
                                  <FormControl fullWidth>
                                    <InputLabel></InputLabel>                                   
                                    <input
                                      type="file"
                                      accept=".jpg, .jpeg, .png, .pdf"
                                      name={`users[${index}].file`}
                                      onChange={(e) => {
                                        formik.setFieldValue(`users[${index}].file`, e.currentTarget.files[0]);
                                      }}
                                      onBlur={formik.handleBlur}
                                      style={{ marginTop: '12px'}}
                                    />
                                    <p style={{ color: '#d32f2f', fontSize: '12px', marginTop: '5px' }}>
                                      {formik.touched.users &&
                                        formik.touched.users[index]?.file &&
                                        formik.errors?.users?.[index]?.file && (
                                          <span style={{ display: 'block' }}>
                                            {formik.errors?.users?.[index]?.file}
                                          </span>
                                        )}
                                    </p>
                                  </FormControl>
                                </Grid>





                                <Grid item md={0.5}>
                                  <IconButton onClick={() => addUser()}>
                                    <AddCircleSharpIcon color='primary' style={{ cursor: 'pointer', fontSize: 35 }} />
                                  </IconButton>
                                </Grid>
                                <Grid item md={0.5}>
                                  <IconButton onClick={() => removeUser(index)}>
                                    <DeleteIcon color='primary' style={{ cursor: 'pointer', fontSize: 35 }} />
                                  </IconButton>
                                </Grid>

                              </Grid>
                            ))}

                          </div>
                          <div style={{ marginTop: '1rem' }}>
                            <Button variant='contained' type="submit" style={{ color: 'white', marginRight: '10px' }}>
                              Submit Claim
                            </Button>
                          </div>

                        </form>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <Snackbar
                  open={snackbarOpen}
                  autoHideDuration={6000}
                  onClose={() => setSnackbarOpen(false)}
                >
                  <Alert severity={snackbarError ? 'error' : 'success'} onClose={() => setSnackbarOpen(false)}>

                    <AlertTitle>{snackbarError ? 'Error' : 'Success'}</AlertTitle>
                    {snackbarMessage}
                  </Alert>
                </Snackbar>
              </body>
            </div>
          </div>
        </>
      ) : (
        <div>        
          <body className="bgcolor">
            <Navbar />
            <div style={{ height: '50px' }} />s
            <div style={{ display: 'flex' }}>
              <Sidenav />



              <div component="main" style={{ flexGrow: 1, padding: '1rem', backgroundColor: '#eef2f6' }}>
                <div style={{ marginBottom: '1rem' }}>
                  <div style={{ paddingBottom: '10px', textAlign: 'left' }}>
                    <div style={{ backgroundColor: '#ffebee', padding: '16px', justifyContent: 'center', alignItems: 'center', display: 'flex', border: '1px solid #e91e63' }}>
                      <Typography variant="h4" color="primary">
                        Employee Details
                      </Typography>
                    </div>
                    <div style={{ height: '60px', backgroundColor: 'white', marginTop: '0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxShadow: '0 3px 3px -2px rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.12)' }}>
                      <TextField
                        disabled
                        id="standard-disabled"
                        label="Name"
                        variant="standard"
                        value={userObj.emp_name}
                        size='small'
                        sx={{ marginRight: '10px', marginLeft: '10px' }}
                      />
                      <TextField
                        disabled
                        id="standard-disabled"
                        label="Department"
                        variant="standard"
                        value={userObj.department}
                        size='small'
                        sx={{ marginRight: '10px' }}
                      />
                      <TextField
                        disabled
                        id="standard-disabled"
                        label="OPD Limit"
                        variant="standard"
                        value={userObj.opd_limit}
                        size='small'
                        sx={{ marginRight: '10px' }}
                      />

                    </div>

                  </div>
                </div>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <form onSubmit={formik.handleSubmit}
                      enableReinitialize={true}>
                      <div component={Box} style={{ boxShadow: '0 3px 3px -2px rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.12)', backgroundColor: 'white' }}>
                        <div style={{ backgroundColor: '#ffebee', padding: '16px', justifyContent: 'center', alignItems: 'center', display: 'flex', border: '1px solid #e91e63' }}>
                          <Typography variant="h4" color="primary">
                            Initiate Claim Request
                          </Typography>
                        </div>
                        <div
                          style={{
                            marginTop: '5px',
                            borderRadius: '8px',
                            marginBottom: '16px',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >

                        </div>
                        {formik.values.users.map((user, index) => (
                          <Grid container spacing={0.5} justifyContent="center" key={index}>

                            <Grid item md={2} p={1}   >
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Relation</InputLabel>
                                <Select
                                  name={`users[${index}].relation`}
                                  value={formik.values.users[index].relation}
                                  label="Relation"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}

                                  error={formik.touched.users && formik.touched.users[index]?.relation && Boolean(formik.errors?.users?.[index]?.relation)}
                                  helperText={formik.touched.users && formik.touched.users[index]?.relation && formik.errors?.users?.[index]?.relation}
                                  style={{
                                    minWidth: '120px'
                                  }}
                                  
                                >
                                  {options.map((option) => (
                                    <MenuItem key={option.label} value={option.label}>
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <p style={{ color: '#d32f2f', fontSize: '12px' }}>
                                  {formik.touched.users &&
                                    formik.touched.users[index]?.relation &&
                                    formik.errors?.users?.[index]?.relation && (
                                      <span style={{ display: 'block' }}>
                                        {formik.errors?.users?.[index]?.relation}
                                      </span>
                                    )}
                                </p>
                              </FormControl>
                            </Grid>

                            <Grid item md={2.5} p={1} elevation={3}>
                              <FormControl fullWidth>
                                <InputLabel >Name</InputLabel>
                                <Select
                                  label="Name"
                                  name={`users[${index}].name`}
                                  fullWidth
                                  value={formik.values.users[index].name}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  error={formik.touched.users && formik.touched.users[index]?.name && Boolean(formik.errors?.users?.[index]?.name)}
                                  helperText={formik.touched.users && formik.touched.users[index]?.name && formik.errors?.users?.[index]?.name}
                                  style={{
                                    minWidth: '120px'
                                  }}
                                >
                                  {options.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.value}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <p style={{ color: '#d32f2f', fontSize: '12px' }}>
                                  {formik.touched.users &&
                                    formik.touched.users[index]?.name &&
                                    formik.errors?.users?.[index]?.name && (
                                      <span style={{ display: 'block' }}>
                                        {formik.errors?.users?.[index]?.name}
                                      </span>
                                    )}
                                </p>
                              </FormControl>
                            </Grid>

                            <Grid item md={2} p={1}>
                              <TextField
                                label="Amount"
                                type="number"
                                name={`users[${index}].amount`}
                                placeholder="Reimbursement Amount"
                                fullWidth
                                value={formik.values.users[index].amount}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.users && formik.touched.users[index]?.amount && Boolean(formik.errors?.users?.[index]?.amount)}
                                helperText={formik.touched.users && formik.touched.users[index]?.amount && formik.errors?.users?.[index]?.amount}
                              />

                            </Grid>
                            <Grid item md={2} p={1}>
                              <FormControl fullWidth>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                    name={`users[${index}].date`}
                                    label='Date'
                                    value={formik.values.users[index].date ? formik.values.users[index].date : null}
                                    variant="outlined"
                                    onChange={(date) => formik.setFieldValue(`users[${index}].date`, date)}
                                    onBlur={formik.handleBlur}
                                  />
                                </LocalizationProvider>
                                {formik.touched.users && formik.touched.users[index]?.date && Boolean(formik.errors?.users?.[index]?.date) && (
                                  <p style={{ color: '#d32f2f', fontSize: '12px' }}>
                                    {formik.errors?.users?.[index]?.date}
                                  </p>
                                )}
                              </FormControl>
                            </Grid>

                            <Grid item md={2} p={1}>
                              <FormControl fullWidth>
                                <InputLabel></InputLabel>
                                <input
                                  type="file"
                                  accept=".jpg, .jpeg, .png, .pdf"
                                  name={`users[${index}].file`}
                                  onChange={(e) => {
                                    formik.setFieldValue(`users[${index}].file`, e.currentTarget.files[0]);
                                  }}
                                  onBlur={formik.handleBlur}
                                  style={{ marginTop: '12px' }}
                                />
                                <p style={{ color: '#d32f2f', fontSize: '12px', marginTop: '5px' }}>
                                  {formik.touched.users &&
                                    formik.touched.users[index]?.file &&
                                    formik.errors?.users?.[index]?.file && (
                                      <span style={{ display: 'block' }}>
                                        {formik.errors?.users?.[index]?.file}
                                      </span>
                                    )}
                                </p>
                              </FormControl>
                            </Grid>





                            <Grid item md={0.5}>
                              <IconButton onClick={() => addUser()}>
                                <AddCircleSharpIcon color='primary' style={{ cursor: 'pointer', fontSize: 35 }} />
                              </IconButton>
                            </Grid>
                            <Grid item md={0.5}>
                              <IconButton onClick={() => removeUser(index)}>
                                <DeleteIcon color='primary' style={{ cursor: 'pointer', fontSize: 35 }} />
                              </IconButton>
                            </Grid>

                          </Grid>
                        ))}

                      </div>
                      <div style={{ marginTop: '1rem' }}>
                        <Button variant='contained' type="submit" style={{ color: 'white', marginRight: '10px' }}>
                          Submit Claim
                        </Button>
                      </div>

                    </form>
                  </Grid>
                </Grid>
              </div>
            </div>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={() => setSnackbarOpen(false)}
            >
              <Alert severity={snackbarError ? 'error' : 'success'} onClose={() => setSnackbarOpen(false)}>

                <AlertTitle>{snackbarError ? 'Error' : 'Success'}</AlertTitle>
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </body>
        </div>


      )}

    </>
  );
};

export default NewMedReq;

