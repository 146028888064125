import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Sidenav from '../components/Sidenav';
import Navbar from '../components/Navbar';
import Swal from 'sweetalert2';
import axios from 'axios';
import '../Home.css';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { jwtDecode } from "jwt-decode";
import img from './bg/VergeMobile_png_1.png'
import { ScaleLoader } from 'react-spinners'


const validationSchema = yup
  .object({
    old_password: yup.string().min(8, 'Password must be at least 8 characters')
      .max(20, 'Password must not exceed 20 characters').required('Old Password is required'),
    new_password: yup.string().min(8, 'Password must be at least 8 characters')
      .max(20, 'Password must not exceed 20 characters')
      .notOneOf([yup.ref('old_password'), null], 'New Password must be different from Old Password')
      .required('New Password is required'),
    confirm_password: yup.string().oneOf([yup.ref('new_password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  })
  .required();

const Settings = () => {
  const [loading, setLoading] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      old_password: '',
      new_password: '',
      confirm_password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const result = await Swal.fire({
        title: 'Password Change',
        text: 'Are you sure you want to change your password.',
        width: 400,
        showCancelButton: true,
        confirmButtonText: 'Yes, change it!',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancel',
      });

      if (result.isConfirmed) {
        const retrievedToken = retrieveToken();
        if (!retrievedToken) {
          return;
        }
        setLoading(true);
        const userData = retrieveUserData();
        const staff_no = userData.staff_no;

        try {
          const response = await axios.post(
            `https://pkclaims.vergemobile.com/api/settings`,
            {
              staff_no: staff_no,
              oldPassword: values.old_password,
              newPassword: values.new_password,
            },
            {
              headers: {
                Authorization: `Bearer ${retrievedToken}`,
              },
            }
          );      setLoading(false);

          Swal.fire({
            title: 'Password changed successfully!',
            timer: 2000,
            width: 400
          }).then(() => {
            navigate('/home');
          });
        } catch (error) {
          console.error(error.response.data.message, error);
          if (error.response.data.error === 'Invalid token.') {
            Swal.fire({
              title: 'Session Expired',
              text: 'Your session has expired. Please log in again.',
              width: 400,
            }).then(() => {
              localStorage.removeItem('token_key');
              localStorage.removeItem('token_expiration');
              navigate('/');
            });
          }
          else if (error.response.data.message === 'Incorrect old password') {
            Swal.fire({
              title: 'Error',
              text: 'Incorrect Old Password',
              width: 400
            });
          }
          else {
            Swal.fire({
              title: 'Error',
              text: 'Error Changing Password',
              width: 400
            });
          }      setLoading(false);

        }
      } else {
        Swal.fire({
          title: 'Password Change Cancelled',
          width: 400
        });
      }
    },
  });

  const retrieveUserData = () => {
    const retrievedToken = localStorage.getItem('token_key');
    const decodedToken = jwtDecode(retrievedToken);
    const user = decodedToken.user;
    const staff_no = decodedToken.user.staff_no
    const emp_name = decodedToken.user.emp_name
    const username = decodedToken.user.username
    return {
      staff_no: staff_no,
      emp_name: emp_name,
      username: username,
      user: user
    };
  };

  const retrieveToken = () => {
    return localStorage.getItem('token_key');
  };

  return (
    <>
    {loading ? (
        <div style={{ backgroundColor: 'black', width: '100vw', height: '100vh', position: 'absolute', zIndex: '5', opacity: '0.7' }}>{/*Top Div*/}
          <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}>
            <img src={img} alt='verge_logo' style={{ height: "90px", marginBottom: "15px" }} />
            <ScaleLoader
              color="#e91e63"
              height={60}
              margin={4}
              radius={2}
              width={6}
            />
          </div>
          
      <div className="bgcolor" style={{
      paddingBottom: '130px',
      height:'100vh'
    }}>
        <Navbar />
        <Box height={70} />
        <Box sx={{ display: 'flex' }}>
          <Sidenav />
          <form onSubmit={formik.handleSubmit}>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <Typography variant="h4" gutterBottom>
                Settings
              </Typography>
              <Paper elevation={3} sx={{ padding: 3, maxWidth: 400 }}>
                <Typography variant="h6" gutterBottom>
                  Change Password
                </Typography>
                <TextField
                  label="Old Password"
                  id="oldPassword"
                  type={showOldPassword ? 'text' : 'password'}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  name="old_password"
                  value={formik.values.old_password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.old_password && Boolean(formik.errors.old_password)}
                  helperText={formik.touched.old_password && formik.errors.old_password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowOldPassword((prev) => !prev)}
                          edge="end"
                        >
                          {showOldPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="New Password"
                  id="newPassword"
                  type={showNewPassword ? 'text' : 'password'}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  name="new_password"
                  value={formik.values.new_password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.new_password && Boolean(formik.errors.new_password)}
                  helperText={formik.touched.new_password && formik.errors.new_password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowNewPassword((prev) => !prev)}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Confirm New Password"
                  id="confirmNewPassword"
                  type={showConfirmNewPassword ? 'text' : 'password'}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  name="confirm_password"
                  value={formik.values.confirm_password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
                  helperText={formik.touched.confirm_password && formik.errors.confirm_password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowConfirmNewPassword((prev) => !prev)}
                          edge="end"
                        >
                          {showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  style={{ marginTop: '16px' }}
                >
                  Change Password
                </Button>
              </Paper>
            </Box>
          </form>
        </Box>
      </div>
     </div>
    ):(
      <div className="bgcolor" style={{
      paddingBottom: '130px',
      height:'100vh'
    }}>
        <Navbar />
        <Box height={70} />
        <Box sx={{ display: 'flex' }}>
          <Sidenav />
          <form onSubmit={formik.handleSubmit}>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <Typography variant="h4" gutterBottom>
                Settings
              </Typography>
              <Paper elevation={3} sx={{ padding: 3, maxWidth: 400 }}>
                <Typography variant="h6" gutterBottom>
                  Change Password
                </Typography>
                <TextField
                  label="Old Password"
                  id="oldPassword"
                  type={showOldPassword ? 'text' : 'password'}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  name="old_password"
                  value={formik.values.old_password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.old_password && Boolean(formik.errors.old_password)}
                  helperText={formik.touched.old_password && formik.errors.old_password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowOldPassword((prev) => !prev)}
                          edge="end"
                        >
                          {showOldPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="New Password"
                  id="newPassword"
                  type={showNewPassword ? 'text' : 'password'}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  name="new_password"
                  value={formik.values.new_password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.new_password && Boolean(formik.errors.new_password)}
                  helperText={formik.touched.new_password && formik.errors.new_password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowNewPassword((prev) => !prev)}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Confirm New Password"
                  id="confirmNewPassword"
                  type={showConfirmNewPassword ? 'text' : 'password'}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  name="confirm_password"
                  value={formik.values.confirm_password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
                  helperText={formik.touched.confirm_password && formik.errors.confirm_password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowConfirmNewPassword((prev) => !prev)}
                          edge="end"
                        >
                          {showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  style={{ marginTop: '16px' }}
                >
                  Change Password
                </Button>
              </Paper>
            </Box>
          </form>
        </Box>
      </div>
    )}
    </>
  );
};

export default Settings;
