import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Sidenav from '../components/Sidenav';
import Box from '@mui/material/Box';
import Navbar from '../components/Navbar';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import DataTable, { Media } from 'react-data-table-component';
import TextField from '@mui/material/TextField';
import { jwtDecode } from "jwt-decode";
import ArrowDownward from '@mui/icons-material/KeyboardArrowDownSharp';
import { ScaleLoader } from 'react-spinners'
import img from './bg/VergeMobile_png_1.png'



const Home = () => {
  const [loading, setLoading] = useState(false);
  const [claimsData, setClaimsData] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredClaims, setfilteredClaims] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClaimData = async () => {
      try {
        const retrievedToken = localStorage.getItem('token_key');
        if (!retrievedToken) {
          return;
        }
        const userData = retrieveUserData();
        const retrievedStaff_no = userData.staff_no;
        setLoading(true);



        const response = await axios.get("https://pkclaims.vergemobile.com/api/approvals", {
          headers: {
            Authorization: `Bearer ${retrievedToken}`,
          },
          params: {
            staff_no: retrievedStaff_no,
          },
        });
        setLoading(false);
        setClaimsData(response.data);
        setfilteredClaims(response.data);
        
      } catch (error) {
        console.error(error.response.data.message, error);
        if (error.response.data.error === 'Invalid token.') {
          Swal.fire({
            title: 'Session Expired',
            text: 'Your session has expired. Please log in again.',
            width: 400,
          }).then(() => {
            localStorage.removeItem('token_key');
            localStorage.removeItem('token_expiration');
            navigate('/');
          });
        }
        if (error.response.data.error !== 'Invalid token.') {
          Swal.fire({
            title: 'Error',
            text: 'An error occurred while getting the claims.',
            width: 400,
          });

        }

        setLoading(false);

      }
      
    };

    fetchClaimData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const result = claimsData.filter(claimdata => {
      return claimdata.emp_name.toLowerCase().match(search.toLowerCase());
    })
    setfilteredClaims(result);
  }, [search]);

  const columns = [
    {
      name: 'Claim#',
      selector: row => row.claim_number,
      sortable: true,
    },
    {
      name: 'Staff No.',
      selector: row => row.staff_no,
      sortable: true,
      hide: Media.MD
    },
    {
      name: 'Employee',
      selector: row => row.emp_name,
      sortable: true,
      hide: Media.MD
    },
    {
      name: 'Claim Date',
      selector: row => row.claim_date,
      sortable: true,
      hide: Media.MD

    },
    {
      name: 'Claim Amount',
      selector: row => row.amount,
      sortable: true,
      hide: Media.SM
    },

    {
      name: 'Action',
      selector: (row) => <Button
        variant="contained"
        onClick={() => navigate('/subclaims_approval', { state: { claimId: row.claim_id, staff_no: row.staff_no } })}
        size="small"
        sx={{
          padding: '4px 8px', // Adjust padding for smaller size
          fontSize: '10px !important',
          fontWeight: 'bold',
          border: '1.2px solid ' // Adjust font size for smaller size
        }}
      >
        View
      </Button>
    },
  ];



  const retrieveUserData = () => {
    const retrievedToken = localStorage.getItem('token_key');
    const decodedToken = jwtDecode(retrievedToken);
    const user = decodedToken.user;
    const staff_no = decodedToken.user.staff_no
    const emp_name = decodedToken.user.emp_name
    const username = decodedToken.user.username
    return {
      staff_no: staff_no,
      emp_name: emp_name,
      username: username,
      user: user
    };
  };



  const customStyles = {

    header: {
      style: {
        fontSize: '1.6rem',
        height: '30px',
        color: 'white',
        background: 'linear-gradient(90deg, #cc2b5e, #753a88)'
      }
    },
    headRow: {
      style: {
        background: 'linear-gradient(90deg, #cc2b5e, #753a88)',
        color: 'white',
        fontSize: '14px',
        fontWeight: 'bold',
        paddingBottom: '10px'
      }
    },
    pagination: {
      style: {
        background: 'linear-gradient(90deg, #cc2b5e, #753a88)',
        color: 'white',
      },
    },
    rows: {
      style: {
        padding: '3px 0px',
        color: 'black',
        // fontWeight: '550',
        fontSize: '0.9rem',
        border: '1px solid #e91e63', // Border color for all rows
      },
    },

  };

  const sortIcon = <ArrowDownward />;

  const customPaginationOptions = {
    rowsPerPageText: 'Rows per page:',
    rangeSeparatorText: 'of',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'All',
  };

  return (
    <>
      {
        loading ? (
          <>
            <div style={{ backgroundColor: 'black', width: '100vw', height: '100vh', position: 'absolute', zIndex: '5', opacity: '0.7' }}>{/*Top Div*/}
              <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}>

              <img src={img} alt='verge_logo' style={{height: "90px", marginBottom: "15px" }}/>
                <ScaleLoader color="#e91e63" height={60} margin={4} radius={2} width={6} />
              </div>
              <div>
                <div className="bgcolor" style={{ paddingBottom: '130px', height: '100vh' }}>
                  <Navbar />
                  <Box height={40} />
                  <Box sx={{ display: 'flex' }}>
                    <Sidenav />
                    <Box component="main" className="bgcolor" sx={{ flexGrow: 1, p: 3 }}>
                      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                        <TextField
                          id="Search"
                          label='Employee'
                          variant="standard"
                          size="small"
                          type='text'
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          sx={{ marginLeft: 'auto' }}
                        />
                      </div>
                      <DataTable
                        columns={columns}
                        data={filteredClaims}
                        title='Claims Approval'
                        pagination
                        paginationComponentOptions={customPaginationOptions}
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[50, 80, 100, 150]}
                        fixedHeader
                        fixedHeaderScrollHeight='350px'
                        selectableRowsRadio="radio"
                        selectableRows
                        selectableRowsHighlight
                        highlightOnHover
                        customStyles={customStyles}
                        responsive
                        striped
                        sortIcon={sortIcon}
                        dense
                      />
                    </Box>
                  </Box>
                </div>
              </div>
            </div>

          </>

        ) : (
          <>
            
                <div className="bgcolor" style={{ paddingBottom: '130px', height: '100vh' }}>
                  <Navbar />
                  <Box height={40} />
                  <Box sx={{ display: 'flex' }}>
                    <Sidenav />
                    <Box component="main" className="bgcolor" sx={{ flexGrow: 1, p: 3 }}>
                      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                        <TextField
                          id="Search"
                          label='Employee'
                          variant="standard"
                          size="small"
                          type='text'
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          sx={{ marginLeft: 'auto' }}
                        />
                      </div>
                      <DataTable
                        columns={columns}
                        data={filteredClaims}
                        title='Claims Approval'
                        pagination
                        paginationComponentOptions={customPaginationOptions}
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[50, 80, 100, 150]}
                        fixedHeader
                        fixedHeaderScrollHeight='350px'
                        selectableRowsRadio="radio"
                        selectableRows
                        selectableRowsHighlight
                        highlightOnHover
                        customStyles={customStyles}
                        responsive
                        striped
                        sortIcon={sortIcon}
                        dense
                      />
                    </Box>
                  </Box>
                </div>

          </>
  
      
  )}
  </>
  )
        }
export default Home;