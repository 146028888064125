import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from "react-router-dom";
import { useAppStore } from '../AppStore';
import HomeSharpIcon from '@mui/icons-material/HomeSharp';
import FiberNewSharpIcon from '@mui/icons-material/FiberNewSharp';
import AssignmentReturnedSharpIcon from '@mui/icons-material/AssignmentReturnedSharp';
import SettingsSuggestSharpIcon from '@mui/icons-material/SettingsSuggestSharp';
import DescriptionSharpIcon from '@mui/icons-material/DescriptionSharp';
import Tooltip from '@mui/material/Tooltip';
import OutboxSharpIcon from '@mui/icons-material/OutboxSharp';
import ExitToAppSharpIcon from '@mui/icons-material/ExitToAppSharp';
import Swal from 'sweetalert2';
import { jwtDecode } from "jwt-decode";
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';




const drawerWidth = 180;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));



const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Sidenav() {
  const theme = useTheme();
  const navigate = useNavigate();
  const open = useAppStore((state) => state.dopen);

  const retrieveUser_role = () => {
     const retrievedToken = localStorage.getItem('token_key');
        if (!retrievedToken) {
          navigate('/');
          return;
        }
    const decodedToken = jwtDecode(retrievedToken);
    const user_role = decodedToken.user.user_role
    return user_role;
  };
  const user_role = retrieveUser_role();

  const handleSignOut = () => {
    Swal.fire({
      title: 'Sign Out Confirmation',
      text: 'Are you sure you want to sign out?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sign Out',
      width: 400
    }).then((result) => {
      if (result.isConfirmed) {
        handleConfirmSignOut();
      }
    });
  };

  const handleConfirmSignOut = () => {
    localStorage.removeItem('token_key');
    navigate('/');
  };


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <Drawer variant="permanent" open={open}  >
        <DrawerHeader>
          <IconButton>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>

          <ListItem disablePadding sx={{ display: 'block' }}
            onClick={() => navigate("/home")}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title="Home" placement="right" color='primary' >

                  <HomeSharpIcon />
                </Tooltip>


              </ListItemIcon>
              <ListItemText primary="Home" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}
            onClick={() => navigate("/newreq")}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title="Create" placement="right" color='primary'>
                  <FiberNewSharpIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Create" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>

          {user_role === 'manager' && (
            <>
              <ListItem disablePadding sx={{ display: 'block' }} onClick={() => navigate("/approvals")}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <Tooltip title="Approvals" placement="right" color='primary'>
                      <AssignmentReturnedSharpIcon />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText primary="Approvals" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding sx={{ display: 'block' }} onClick={() => navigate("/appr_claims")}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <Tooltip title="Processed" placement="right" color='primary'>
                      <CheckCircleOutlineSharpIcon />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText primary="Processed" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding sx={{ display: 'block' }} onClick={() => navigate("/csv_exports")}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                    <Tooltip title="Csv Exports" placement="right" color='primary'>
                      <OutboxSharpIcon />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText primary="Csv Exports" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </>
          )}

          <ListItem disablePadding sx={{ display: 'block' }}
            onClick={() => navigate("/claimsdata")}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              > <Tooltip title="My Claims" placement="right" color='primary'>
                  <DescriptionSharpIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="My Claims" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding sx={{ display: 'block' }}
            onClick={() => navigate("/settings")}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title="Settings" placement="right" color='primary' >
                  <SettingsSuggestSharpIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Settings" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }} onClick={handleSignOut}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >


                <Tooltip title="Sign Out" placement="right" color='primary'>
                  <ExitToAppSharpIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Sign Out" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
      </Drawer>



    </Box>
  );
}
