import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import AlertTitle from '@mui/material/AlertTitle';
import Paper from '@mui/material/Paper';
import bgimg from './bg/1534714_1025_3.jpg'
import bgimgbox from './bg/VergeMobile_jpg.jpg'
import { useFormik } from 'formik';
import * as yup from "yup";
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { jwtDecode } from "jwt-decode";
import Link from '@mui/material/Link';



const validationSchema = yup
  .object({
    email: yup.string().email().required('Email is required'),
    password: yup.string().required('Password is required'),
  })
  .required()


export default function SignIn() {
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const currentYear = new Date().getFullYear();
  const [copyright] = useState(`Copyright © ${currentYear}, All Rights Reserved.`);



  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await axios.post('https://pkclaims.vergemobile.com/api/login',
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            params: {
              username: values.email,
              password: values.password,
            }
          },
        );
        const token = response.data.token;
        const decodedToken = jwtDecode(token);
        const token_expiration = decodedToken.exp * 1000;

        localStorage.setItem('token_key', token);
        localStorage.setItem('token_expiration', token_expiration);

        setSnackbarSeverity('success');
        setSnackbarMessage(`${response.data.message}`);
        setSnackbarOpen(true);

        await new Promise((resolve) => setTimeout(resolve, 1000));
        localStorage.removeItem('user');
        navigate('/home');

      } catch (error) {
        console.error('Error during login:', error);
        setSnackbarSeverity('error');
        setSnackbarMessage(`${error.response.data.message}`);
        setSnackbarOpen(true);

      }
    },
  });


  const handleClickShowPassword = () => {
    const input = document.getElementById('password');
    const currentPosition = input.selectionStart;
    setShowPassword((show) => !show);
    setTimeout(() => {
      input.setSelectionRange(currentPosition, currentPosition);
    }, 0);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };



  return (
    <>
      <div
        style={{
          overflow: 'hidden',
          backgroundImage: `url(${bgimg})`,
          justifyContent: 'center',
          display: 'flex',
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div
            style={{
              width: '400px',
              backgroundColor: 'white',
              marginTop: '50px',
              marginBottom: '50px',
              padding: '20px',
              // height:'400px'
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <img src={bgimgbox} alt="Background Image" style={{ height: '70px', width: '85%' }} />
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Typography component="h1" variant="h5" color='primary' sx={{
                display: 'flex', alignItems: 'center', paddingLeft: 1.2, mt: 3, fontWeight: 'bold',
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)'
              }}>
                Claim Management System
              </Typography>
            </div>

            <div>
              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Email Address*"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="email"
                inputProps={{ maxLength: 50 }}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                margin="normal"
                fullWidth
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="Password*"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                inputProps={{ maxLength: 50 }}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div>
              <div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 4, mb: 1, fontWeight: 'bold' }}
                >
                  Sign In
                </Button>
              </div>
              <div

                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                <Typography variant="body2" color="textSecondary" >
                  {copyright}
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                <Link href="/forgot_password" variant="body2">
                  Forgot password?
                </Link>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        closeable={false}
      >
        <Alert
          severity={snackbarSeverity}
          variant='filled'
          sx={{ width: '100%' }}
        >
          <AlertTitle>{snackbarSeverity === 'success' ? 'success' : 'Error'}</AlertTitle>
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </>
  );
}