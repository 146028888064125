import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MuiAppBar from '@mui/material/AppBar';
import { useAppStore } from '../AppStore';
import DensityMediumSharpIcon from '@mui/icons-material/DensityMediumSharp';
import { jwtDecode } from 'jwt-decode';
import favicon from '../pages/bg/favicon.jpg'
import favicon2 from '../pages/bg/favicon2.png'
import user1 from '../pages/bg/user3.png'


const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

export default function Navbar() {
  const retrievedToken = localStorage.getItem('token_key');
  const userData = retrievedToken ? retrieveUserData() : {};
  const emp_name = userData.emp_name || '';

  const updateOpen = useAppStore((state) => state.updateOpen);
  const dopen = useAppStore((state) => state.dopen);


  const mobileMenuId = 'primary-search-account-menu-mobile';

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" elevation={0} sx={{ background: 'linear-gradient(45deg, #7b4397, #dc2430)' }}>
        <Toolbar>
          <div
            style={{
              display: 'flex',
              // backgroundColor: 'green',
              width: '100%'
            }}>
            <div
              style={{
                display: 'flex',
                // backgroundColor: 'red',
                width: '100%'
              }}
            >
              <div
                style={{
                  display: 'inline',
                  margin: '0px',
                  padding: '0px',
                  // backgroundColor: 'red',
                  width: '40px'
                }}>

                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  sx={{ mr: 2 }}
                  onClick={() => updateOpen(!dopen)}
                >
                  <DensityMediumSharpIcon />
                </IconButton>
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  // backgroundColor: 'white',
                  paddingTop: '5px'
                  // justifyContent: 'center'
                }}
              >
                <div style={{
                marginTop:'5px'
                }}>
                  <img src={favicon} alt="Background Image" style={{ height: '30px', width: '30px' }} />
                </div>
                <div style={{
                marginTop:'6px',
                marginLeft:'5px',
                }}>
                  <img src={favicon2} alt="Background Image" style={{ height: '30px', width: '150px' }} />
                </div>
              </div>
            </div>{/*left div*/}

            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                // backgroundColor: 'black',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <div style={{
                display: 'flex',
                width: '100%'
              }}
              >
              </div>
              <div
                style={{
                  display: 'inline',
                  // marginRight: '10px',
                  width: '240px',
                  paddingTop: '10px',
                  paddingBottom: '10px',

                  // backgroundColor:'yellow'
                }}
              >
                {/* <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  color="inherit"
                > */}
                <Typography
                // sx={{
                //   display: { xs: 'none', sm: 'block' },
                //   fontSize: '14px',
                // }}
                >
                  Welcome,<b> {emp_name} </b>
                </Typography>

                {/* </IconButton> */}
              </div>

              <div
                style={{
                  marginLeft: '5px',
                  // backgroundColor: 'green',
                  paddingTop: '10px',
                  paddingBottom: '10px',


                }}>
                {/* <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  color="inherit"
                /> */}
                <img src={user1} alt="Background Image" style={{ height: '35px', width: '35px' }} />
                {/* <AccountCircle /> */}
              </div>
            </div> {/* Right Div */}
            {/* </Box> */}

          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

const retrieveUserData = () => {
  const retrievedToken = localStorage.getItem('token_key');
  const decodedToken = jwtDecode(retrievedToken);
  const staff_no = decodedToken.user.staff_no;
  const emp_name = decodedToken.user.emp_name;
  return {
    staff_no: staff_no,
    emp_name: emp_name,
  };
};
